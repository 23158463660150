<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('chargePlace.cpTitle') }} {{ $t('common.create') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <!-- Station Info Start-->
        <div class="ion-modal-form-group-title">{{ $t('chargePlace.cpInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cp-create-name">
              <span>{{ $t('chargePlace.placeName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-create-name"
                          v-model="form.placeName"
                          :class="{ 'is-invalid': submitted && $v.form.placeName.$error }" />
            <div v-if="submitted && $v.form.placeName.$error" class="invalid-feedback">
              <span v-if="!$v.form.placeName.required">{{ $t('validation.cpNameIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cp-create-phone">
              <span>{{ $t('chargePlace.busiCall') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-create-phone"
                          v-model="form.busiCall"
                          :class="{ 'is-invalid': submitted && $v.form.busiCall.$error }" />
            <div v-if="submitted && $v.form.busiCall.$error" class="invalid-feedback">
              <span v-if="!$v.form.busiCall.required">{{ $t('validation.busiCallIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-8">
            <label class="form-label" for="cp-create-address">
              <span>{{ $t('chargePlace.address') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="d-flex">
              <b-form-input class="form-control"
                            type="text"
                            id="cp-create-address"
                            v-model="form.address"
                            :class="{ 'is-invalid': submitted && $v.form.address.$error }" />
              <button :disabled="$_utils.isEmpty(form.address)" type="button"
                      class="btn btn-outline-success text-nowrap"
                      @click="getGeoCodingData">{{ $t('chargePlace.inputLatLng') }}</button>
            </div>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-create-detail">{{ $t('chargePlace.detailLocation') }}</label>
            <b-form-input class="form-control" type="text" id="cp-create-detail" v-model="form.detailLocation" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cp-create-use-time">
              <span>{{ $t('chargePlace.useTime') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-create-use-time"
                          v-model="form.useTime"
                          :class="{ 'is-invalid': submitted && $v.form.useTime.$error }" />
            <div v-if="submitted && $v.form.useTime.$error" class="invalid-feedback">
              <span v-if="!$v.form.useTime.required">{{ $t('validation.useTimeIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-3">
            <label class="form-label" for="cp-create-lat">{{ $t('common.lat') }}</label>
            <b-form-input class="form-control" type="text" id="cp-create-lat" v-model="form.lat" />
          </div>
          <div class="col-xl-3">
            <label class="form-label" for="cp-create-lng">{{ $t('common.lng') }}</label>
            <b-form-input class="form-control" type="text" id="cp-create-lng" v-model="form.lng" />
          </div>
        </div>
        <!-- Station Info End-->

        <!-- File Upload Start -->
        <div class="row mb-4">
          <div class="col-xl-4">
            <label class="form-label" for="cp-create-file1">{{ $t('common.imageFile') }} 1</label>
            <div class="d-flex">
              <input class="form-control" type="file" accept="image/*" id="cp-create-file1"
                     ref="chargePlaceFile1" @change="handleFileChange(1)" />
              <button v-if="file1" type="button" class="btn btn-outline-danger" @click="fileDel(1)">Delete</button>
            </div>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-create-file2">{{ $t('common.imageFile') }} 2</label>
            <div class="d-flex">
              <input class="form-control" type="file" accept="image/*" id="cp-create-file2"
                     ref="chargePlaceFile2" @change="handleFileChange(2)" />
              <button v-if="file2" type="button" class="btn btn-outline-danger" @click="fileDel(2)">Delete</button>
            </div>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-create-file3">{{ $t('common.imageFile') }} 3</label>
            <div class="d-flex">
              <input class="form-control" type="file" accept="image/*" id="cp-create-file3"
                     ref="chargePlaceFile3" @change="handleFileChange(3)" />
              <button v-if="file3" type="button" class="btn btn-outline-danger" @click="fileDel(3)">Delete</button>
            </div>

          </div>
        </div>
        <!-- File Upload End -->

        <!-- Plan Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('chargePlace.planInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cp-create-plan">
              <span>{{ $t('chargePlace.planName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="cp-create-plan"
                         v-model="form.plan"
                         label="text" track-by="value"
                         :options="planOptions"
                         :placeholder="$t('alert.selectPlan')"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false"
                         @input="setDefaultPlanInfo" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cp-create-plan-date">{{ $t('chargePlace.planDate') }}</label>
            <div class="row">
              <date-picker type="date" format="YYYY-MM-DD" id="valid-to-date"
                           v-model="form.planDate"
                           value-type="format"
                           :disabled-date="notBeforeToday" />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-4">
            <label class="form-label" for="cp-create-server-fee">{{ $t('chargePlace.serverUsageFee') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-create-server-fee"
                          v-model="form.serverUsageFee" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-create-settlement-rate">{{ $t('chargePlace.settleRate') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-create-settlement-rate"
                          v-model="form.settleRate" />
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="cp-create-profit-dist-rate">{{ $t('chargePlace.profitDistribution') }}</label>
            <b-form-input class="form-control"
                          type="text"
                          id="cp-create-profit-dist-rate"
                          :disabled="form.plan?.value === 10000000000224"
                          v-model="form.profitDistRate" />
          </div>
        </div>
        <!-- Plan Info End -->

        <!-- Settlement Info Start -->
        <div class="ion-modal-form-group-title">{{ $t('chargePlace.settlementInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="cp-create-bank">{{ $t('chargePlace.bankName') }}</label>
            <b-form-input class="form-control" type="text" id="cp-create-bank" v-model="form.bank" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cp-create-settlement-day">{{ $t('chargePlace.settleDay') }}</label>
            <div class="row">
              <div class="col-2 text-center align-content-center">{{ $t('chargePlace.monthly') }}</div>
              <div class="col-4">
                <b-form-input class="form-control" type="text" id="cp-create-settlement-day" v-model="form.settleDay" />
              </div>
              <div class="col-6 text-start align-content-center">{{ $t('chargePlace.day') }}</div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-6">
            <label class="form-label" for="cp-create-account-holder">{{ $t('chargePlace.accountHolder') }}</label>
            <b-form-input class="form-control" type="text" id="cp-create-account-holder" v-model="form.bankAccountHolder" />
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="cp-create-account-number">{{ $t('chargePlace.accountNumber') }}</label>
            <b-form-input class="form-control" type="text" id="cp-create-account-number" v-model="form.bankAccountNo" />
          </div>
        </div>
        <!-- Settlement Info End -->

        <!-- Hq Info Start -->
        <template v-if="$_app.user.roles[0] === 'ADMIN'">
          <div class="ion-modal-form-group-title">{{ $t('chargePlace.hqInfo') }}</div>
          <div class="row">
            <div class="col-xl-6">
              <label class="form-label" for="cp-create-hq">
                <span>{{ $t('common.headquarters') }}</span>
                <i class="uil uil-asterisk text-danger ms-1"></i>
              </label>
              <multiselect id="cp-create-hq"
                           v-model="hqId"
                           label="text" track-by="value"
                           :options="hqOptions"
                           :placeholder="$t('alert.selectHq')"
                           selectLabel=""
                           selectedLabel=""
                           :deselectLabel="$t('common.unselect')"
                           :searchable="false" :allow-empty="true"
                           :class="{ 'is-invalid': submitted && $v.hqId.$error }" />
              <div v-if="submitted && $v.hqId.$error" class="invalid-feedback">
                <span v-if="!$v.hqId.required">{{ $t('validation.hqIsRequired') }}</span>
              </div>
            </div>
            <div class="col-xl-6"></div>
          </div>
        </template>

        <!-- Hq Info End -->

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';

export default {
  components: { Multiselect },
  data() {
    return {
      visible: false,
      submitted: false,
      hqOptions: [],
      planOptions: [],
      planDefaultInfos: null,
      form: {
        placeName: null,
        busiCall: null,
        address: null,
        detailLocation: null,
        useTime: null,
        lat: null,
        lng: null,
        plan: null,
        planDate: null,
        serverUsageFee: null,
        settleRate: null,
        profitDistRate: null,
        settleDay: null,
        bank: null,
        bankAccountHolder: null,
        bankAccountNo: null,
      },
      file1: null,
      file2: null,
      file3: null,
      hqId: null
    }
  },
  validations() {
    const isHqRequired = this.$_app.user.roles[0] === 'ADMIN';
    return {
      form: {
        placeName: { required },
        busiCall: { required },
        address: { required },
        useTime: { required },
      },
      hqId: {
        required: isHqRequired ? required : false
      }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getHqOptions();
      vm.getPlanOptions();

      vm.visible = true;
    },
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    getPlanOptions() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlacePlan/list';

      vm.planOptions = [];

      http.post(_url, {
        page: 1,
        limit: 10,
        searchConditions: [],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        vm.planDefaultInfos = res.data.items;
        res.data.items.forEach(item => {
          vm.planOptions.push({ text: item.planName, value: item.id });
        });
      }).then(() => {
        vm.form.plan = vm.planOptions.find(ele => ele.value === 10000000000225);
        vm.setDefaultPlanInfo();
      }).catch(err => {
        console.error('Business Plan List Error :: ', err)
      });
    },
    notBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    setDefaultPlanInfo() {
      const vm = this;

      const selectedPlan = vm.planDefaultInfos.find(ele => ele.id === vm.form.plan.value)

      vm.form = {
        ...vm.form,
        serverUsageFee : selectedPlan.serverUsageFee,
        settleRate : selectedPlan.settleRate,
        profitDistRate : selectedPlan.profitDistRate,
      }
    },
    getGeoCodingData() {
      const vm = this;
      const _url = `/onmapi/utils/geocoding/latlng?address=${vm.form.address}`;

      http.get(_url).then(res => {
        if (res) {
          const location = res.data.item;

          vm.form.lat = location.lat;
          vm.form.lng = location.lng;
        }

      }).catch(err => {
        console.error('Get GeoCoding Data Error :: ', err)
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.geocodingError'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        placeName: null,
        busiCall: null,
        address: null,
        detailLocation: null,
        useTime: null,
        lat: null,
        lng: null,
        plan: null,
        planDate: null,
        serverUsageFee: null,
        settleRate: null,
        profitDistRate: null,
        settleDay: null,
        bank: null,
        bankAccountHolder: null,
        bankAccountNo: null,
      }
      vm.hqOptions = [];
      vm.planOptions = [];
      vm.planDefaultInfos = null;

      vm.file1 = null;
      vm.file2 = null;
      vm.file3 = null;
      vm.hqId = null;

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    handleFileChange(fileNumber) {
      const vm = this;
      vm[`file${fileNumber}`] = vm.$refs[`chargePlaceFile${fileNumber}`].files[0];
    },
    fileDel(fileNumber) {
      const vm = this;
      vm[`file${fileNumber}`] = null;
      this.$refs[`chargePlaceFile${fileNumber}`].value = null;
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmCreate'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/create';

      const _formData = new FormData();

      _formData.append('placeName', vm.form.placeName);
      _formData.append('busiCall', vm.form.busiCall);
      _formData.append('address', vm.form.address);
      _formData.append('useTime', vm.form.useTime);

      const _formFields = [
        { key: 'detailLocation', value: vm.form.detailLocation },
        { key: 'lat', value: vm.form.lat },
        { key: 'lng', value: vm.form.lng },
        { key: 'serverUsageFee', value: vm.form.serverUsageFee },
        { key: 'settleRate', value: vm.form.settleRate },
        { key: 'commissionRate', value: vm.form.profitDistRate },
        { key: 'settleDay', value: vm.form.settleDay },
        { key: 'bank', value: vm.form.bank },
        { key: 'bankAccountHolder', value: vm.form.bankAccountHolder },
        { key: 'bankAccountNo', value: vm.form.bankAccountNo }
      ];

      _formFields.forEach(field => {
        if (!this.$_utils.isEmpty(field.value)) {
          _formData.append(field.key, field.value);
        }
      });

      if (!this.$_utils.isEmpty(vm.form.plan)) {
        _formData.append('planId', vm.form.plan.value);
      }

      if (!this.$_utils.isEmpty(vm.form.planDate)) {
        _formData.append('planDate', vm.form.planDate + ' 00:00:00');
      }

      if (this.$_app.user.roles[0] === 'ADMIN') {
        if (!this.$_utils.isEmpty(vm.hqId)) {
          _formData.append('hqId', vm.hqId.value)
        }
      } else if (this.$_app.user.roles[0] === 'HQ') {
        _formData.append('hqId', this.$_app.user.hqId)
      }

      if (!this.$_utils.isEmpty(vm.file1)) {
        _formData.append('file1', vm.file1);
      }

      if (!this.$_utils.isEmpty(vm.file2)) {
        _formData.append('file2', vm.file2);
      }

      if (!this.$_utils.isEmpty(vm.file3)) {
        _formData.append('file3', vm.file3);
      }

      http.post(_url, _formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.created'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Charge Place Create Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
